import axios from 'axios';

const ranchatApiCall = (code, packagename, success, error) => {
  axios.delete('https://api.strangerchat.io/api/v1/ranchatusers/code/' + code.toUpperCase() + '?package=' + packagename)
  // axios.delete('http://localhost:8090/api/v1/ranchatusers/code/' + code + '?package=' + packagename)
      .then(success)
      .catch(error)
}

const initDataAccount1 = {
  "icon": "",
  "name": "",
  "promotion": "",
  "by": "gaga.chat",
  "package": "",
  "api_call": ranchatApiCall
}

const initDataAccount2 = {
  ...initDataAccount1,
  "by": "Gaga&Talk",
}

const initDataAccount3 = {
  ...initDataAccount1,
  "by": "stranger.chat",
}

export const appInfo = {
  "randomchat3": {
    "by": "gagasoft",
    "icon": "https://cdn.forestsoft.io/randomchat/icon.png",
    "name": "Random Chat - Stranger Chat",
    "package": "com.gagasoft.randomchat",
    "promotion": "https://cdn.forestsoft.io/randomchat/promotion.png"
  },
  "strangerhub": {
    "by": "stranger.hub",
    "icon": "https://lh3.googleusercontent.com/Gdjf_c62liZuUVUMsHIpho8-PYLqt8enTTKjOkgcGJBM6mOcRHF0d0v2o2ciGo8IADI",
    "name": "Stranger HUB",
    "package": "com.sysoft.strangerhub",
    "promotion": "https://lh3.googleusercontent.com/Ww8nUe5wrgeukOUeOtSWcLVbKNlfsxOjX169qDTDA7fYjRU0Wo8X06yQYviC-pHGQ4AV"
  },
  "aichat": {
    "by": "stranger.hub",
    "icon": "https://lh3.googleusercontent.com/48yUxn75GpzVGp89-YOmY22oGMliPps3xEazezmJgt2m-D_wT0TKROM7J8JEZrWnSdo",
    "name": "AI Chat",
    "package": "com.sysoft.aichat",
    "promotion": "https://lh3.googleusercontent.com/DdHLC21zC28ffJG401GPb2av-M_rDk3a2r4-vtoPxLqHuC48DH9xb_9NjtKHhiMFAw",
    "api_call": (code, packagename, success, error) => {
      axios.delete('https://api.secretary.chat/chatusers/code/' + code.toUpperCase())
        // axios.delete('http://localhost:8090/api/v1/ranchatusers/code/' + code + '?package=' + packagename)
        .then(success)
        .catch(error)
    }
  },
  "secretchat": {
    "by": "ParkMaro",
    "icon": "https://cdn.forestsoft.io/secretchat/icon.png",
    "name": "Secret Chat (Random Chat)",
    "promotion": "https://cdn.forestsoft.io/secretchat/promotion.png",
    "package": "com.suyong.secretchat",
  },
  "gagachat": {
    "by": "hanil soft",
    "icon": "https://cdn.forestsoft.io/gagasoft/icon.png",
    "name": "Gaga Chat",
    "package": "com.hanilsoft.gagachat",
    "promotion": "https://cdn.forestsoft.io/gagasoft/promotion.png"
  },
  "randomtalk": {
    "by": "double.berry",
    "icon": "https://cdn.forestsoft.io/randomtalk/icon.png",
    "name": "Random Talk, Stranger Chat",
    "package": "com.doubleberry.randomtalk2",
    "promotion": "https://cdn.forestsoft.io/randomtalk/promotion.png"
  },
  "lovetalk": {
    "by": "Ryan.dev",
    "icon": "https://cdn.forestsoft.io/lovetalk/icon.png",
    "name": "Random Talk, Stranger Chat",
    "package": "com.ryan.lovetalk",
    "promotion": "https://cdn.forestsoft.io/lovetalk/promotion.png"
  },
  "heartlabs": {
    "by": "blossome",
    "icon": "https://cdn.forestsoft.io/heartlabs/icon.png",
    "name": "Ranchat - Random Chat",
    "package": "com.heartlabs.ranchat",
    "promotion": "https://cdn.forestsoft.io/heartlabs/promotion.png"
  },
  "sunshineseoul": {
    "by": "sunshine.seoul",
    "icon": "https://cdn.forestsoft.io/sunshineseoul/icon.png",
    "name": "Honey Talk",
    "package": "com.sunshineseoul.ranchat",
    "promotion": "https://cdn.forestsoft.io/sunshineseoul/promotion.png"
  },
}